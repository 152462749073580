import EpgStore from './epg.store';

import { amsClient } from 'src/service/ams';
import StoreWrapperService from 'src/store/store-wrapper.js';
// import Logger from 'src/utils/logger';

export let EpgService = new StoreWrapperService({
  name: 'epg',
  localStore: EpgStore,
  options: {
    mutations: true,
    getters: true,
    actions: true,
  },
  ignore: {
    mutations: [],
    getters: [],
    actions: [],
  },
  init: ({ store, name }) => {
    /** no need in zman */
    // amsClient.on('sync', (event, data) => {
    //   setTimeout(() => {
    //     store.dispatch(`${name}/onSync`, data);
    //   });
    // });
    // amsClient.on('auth_state_change', (event, data) => {
    //   setTimeout(() => {
    //     if (data.type === 'logout') {
    //       store.dispatch(`${name}/refreshChannels`, data);
    //     }
    //   });
    // });
  },
});

export default EpgService;
