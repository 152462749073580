import formatDate from 'src/filters/formatDate';
import isString from 'lodash/isString';
import { amsClient } from '../../service/ams';

// NOTE: CURRENT_TIMEZONE_OFFSET: for time zone UTC+2:00, '-120' will be returned.
const STATE_CONST = {
  AIS_HARDCODED_TIME_ZONE_OFFSET: 7 * 60 * 60 * 1000,
  CURRENT_TIMEZONE_OFFSET: new Date().getTimezoneOffset() * 60 * 1000,
  NORMALISE_TIME: 5 * 60 * 1000,
  AUTO_REFRESH_EPG_INTERVAL: 1 * 60 * 1000,
  EPG_FUTURE_TIME: 5 * 60 * 1000,
  EPG_FUTURE_BIG_TIME: 24 * 60 * 60 * 1000,
  EPG_FUTURE_BIG_TIMEOUT: 1 * 60 * 1000,
  BIG_NORMALISE_TIME: 4 * 60 * 60 * 1000,
};

let normaliseDate = function(date, normaliseTime = 0) {
  let normaliseDate = date - (date % normaliseTime);
  return (
    normaliseDate +
    STATE_CONST.AIS_HARDCODED_TIME_ZONE_OFFSET +
    STATE_CONST.CURRENT_TIMEZONE_OFFSET
  );
};

let toTimeStamp = function(date, time, timeZoneOffset = 0) {
  let _d = date.split('-');
  let _t = time.split(':');
  return (
    Date.UTC(+_d[0], +_d[1] - 1, +_d[2], +_t[0], +_t[1], +_t[2]) -
    timeZoneOffset
  );
};

const state = () => ({
  // epglist: { id: [{item1}, {item2}] },
  epglist: {},
  // activeEpgs: {id: {item}, id2: {item2}},
  activeEpgs: {},
  //
  channels: null,
  activeChannel: null,
  isLoading: true,
  channelsId: [],
  timeInterval: null,
});

const getters = {
  getCurrentEpg(state) {
    return channel => {
      // console.log(channel,  state.activeEpgs);
      return state && state.activeEpgs && state.activeEpgs[channel]
        ? state.activeEpgs[channel]
        : null;
    };
  },

  _getChannelsIdStr(state) {
    return state.channels && state.channels.length ? state.channels.map(el => el.id).join(',') : null;
  },

  _getIntervalId(state) {
    return state.timeInterval;
  },

  getEpgByChannel(state) {
    return chid => {
      return state.epglist[chid] || [];
    };
  },

  getChannels(state) {
    return state.channels || [];
  },

  getLoading(state) {
    return state.isLoading;
  },

  getActiveChannel(state) {
    return state.activeChannel || null;
  },
};

const mutations = {
  setEpgList(state, items) {
    if (items && items.length) {
      let dt = [];
      items.forEach(el => {
        el.startDateTime = toTimeStamp(
          el.date,
          el.start,
          STATE_CONST.AIS_HARDCODED_TIME_ZONE_OFFSET,
        );
        el.endDateTime = toTimeStamp(
          el.date_end,
          el.end,
          STATE_CONST.AIS_HARDCODED_TIME_ZONE_OFFSET,
        );
        dt[el.parent] = dt[el.parent] || [];
        dt[el.parent].push(el);
      });
      Object.keys(dt).forEach(key => {
        state.epglist[key] = dt[key].sort((epg1, epg2) => {
          return epg1.startDateTime - epg2.startDateTime;
        });
      });
      state.epglist = Object.assign({}, dt);
    }
  },

  setEpgById(state, { items, id }) {
    items.forEach(el => {
      el.startDateTime = toTimeStamp(
        el.date,
        el.start,
        STATE_CONST.AIS_HARDCODED_TIME_ZONE_OFFSET,
      );
      el.endDateTime = toTimeStamp(
        el.date_end,
        el.end,
        STATE_CONST.AIS_HARDCODED_TIME_ZONE_OFFSET,
      );
    });
    let dt = state.epglist;
    dt[id] = items.sort((epg1, epg2) => {
      return epg1.startDateTime - epg2.startDateTime;
    });
    state.epglist = Object.assign({}, dt);
  },

  setActiveEpg(state) {
    let list = Object.keys(state.epglist);
    let now = Date.now();
    let data = {};
    for (let keyIter = 0; keyIter < list.length; keyIter++) {
      for (let i = 0; i < state.epglist[list[keyIter]].length; i++) {
        let item = state.epglist[list[keyIter]][i];
        if (item.startDateTime <= now && item.endDateTime >= now) {
          data[list[keyIter]] = item;
        } else if (item.startDateTime > now) {
          break;
        }
      }
    }
    state.activeEpgs = Object.assign({}, data);
  },

  setIntervalId(state, value) {
    state.timeInterval = value;
  },

  setLoading(state, value) {
    state.isLoading = value;
  },

  setChannels(state, items) {
    state.channels = items && items.length ? Object.assign([], items) : null;
  },

  setActiveChannel(state, item) {
    let key = item ? (isString(item) ? item : item.id) : item === 0 ? 0 : null;
    let index = state?.channels.findIndex(el => (el.id === key || key === 0));
    state.activeChannel = index > -1 ? Object.assign({}, state.channels[index]) : null;
  },
};

const actions = {
  getEpgList({ dispatch, getters, commit }) {
    let items = getters._getChannelsIdStr;
    let dateStart = normaliseDate(Date.now(), STATE_CONST.NORMALISE_TIME);
    let dateEnd = normaliseDate(Date.now() + STATE_CONST.EPG_FUTURE_TIME, STATE_CONST.NORMALISE_TIME);

    let url = `/epg/?start_date=${formatDate(
      dateStart,
      'yyyy-MM-dd',
    )}&end_date=${formatDate(dateEnd, 'yyyy-MM-dd')}&start_time=${formatDate(
      dateStart,
      'HH:mm:ss',
    )}&end_time=${formatDate(dateEnd, 'HH:mm:ss')}&items=${items}`;

    return amsClient.callAms(url, { cache: false })
      .then(function(data) {
        if (data && data.items) {
          commit(`setEpgList`, data.items);
          commit(`setActiveEpg`);
          dispatch(`enableAutoRefreshEpg`);
          setTimeout(() => {
            dispatch(`getEpgListForOneDay`);
          }, STATE_CONST.EPG_FUTURE_BIG_TIMEOUT);
          return data.items;
        }
        return [];
      });
  },
  getEpgListForOneDay({ getters, commit }) {
    let items = getters._getChannelsIdStr;
    let dateStart = normaliseDate(Date.now(), STATE_CONST.BIG_NORMALISE_TIME);
    let dateEnd = normaliseDate(Date.now() + STATE_CONST.EPG_FUTURE_BIG_TIME, STATE_CONST.BIG_NORMALISE_TIME);

    let url = `/epg/?start_date=${formatDate(
      dateStart,
      'yyyy-MM-dd',
    )}&end_date=${formatDate(dateEnd, 'yyyy-MM-dd')}&start_time=${formatDate(
      dateStart,
      'HH:mm:ss',
    )}&end_time=${formatDate(dateEnd, 'HH:mm:ss')}&items=${items}`;

    // FIXME: delete timeout is user lost the page;
    if (items) {
      return amsClient.callAms(url, { cache: false })
        .then(function(data) {
          if (data && data.items) {
            commit(`setEpgList`, data.items);
            return data.items;
          }
          return [];
        });
    } else {
      console.warn(`You lost the channelpage`);
    }
  },
  enableAutoRefreshEpg({ commit, getters, dispatch }) {
    if (!getters._getIntervalId) {
      let intervalID = setInterval(() => {
        commit(`setActiveEpg`);
      }, STATE_CONST.AUTO_REFRESH_EPG_INTERVAL);
      commit(`setIntervalId`, intervalID);
    }
  },

  disableAutoRefreshEpg({ getters, commit }) {
    if (getters._getIntervalId) {
      clearInterval(getters._getIntervalId);
      commit(`setIntervalId`, null);
      commit(`setChannels`, null);
    }
  },

  getEpgById({ commit }, { id }) {
    return amsClient.callAms(`/epg/?items=${id}`, { cache: false })
      .then(function(data) {
        if (data && data.items) {
          commit(`setEpgById`, { items: data.items, id: id });
          commit(`setActiveEpg`);
          return data.items;
        }
        return [];
      });
  },

  getRemoteChannels({ commit, dispatch }) {
    commit(`setLoading`, true);
    return amsClient.callAms('/get_channels/', { cache: false }).then(data => {
      if (!data.error) {

        let items = data.items;
        items.map((item) => {
          item.pss = {
            canPlayPremium: amsClient.canPlayPremium(item),
            canPlayFreemium: amsClient.canPlayFreemium(item),
            isPremium: amsClient.isContentPremium(item),
            isFreemium: amsClient.isContentFreemium(item),
            canPlay: !item.not_free || (amsClient.isContentFreemium(item) && amsClient.canPlayFreemium(item)) || (amsClient.isContentPremium(item) && amsClient.canPlayPremium(item)),
          };
        });
        commit(`setChannels`, items);
        dispatch(`getEpgList`);
      }
      return data;
    }).finally(() => {
      commit(`setLoading`, false);
    });
    // return this._vm
    //   .$longCache({
    //     url: url,
    //   }).then(response => {
    //       if (!response.data.error) {
    //         commit(`setChannels`, response.data.items);
    //         dispatch(`getEpgList`);
    //       }
    //       return response.data;
    //   }).finally(() => {
    //     commit(`setLoading`, false);
    //   });
  },
  onSync({ state, dispatch, getters, commit }, data) {
    /** to refresh only if there is any changes*/
    if (Object.keys(data.addedProducts).length || Object.keys(data.removedProducts).length) {
      dispatch('refreshChannels');
    }
  },
  refreshChannels({ state, dispatch, getters, commit }, data) {
    return amsClient.callAms('/get_channels/', { cache: false }).then(data => {
      if (!data.error) {
        let items = data.items;
        commit(`setChannels`, items);
        dispatch(`getEpgList`);
      }
      return data;
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
